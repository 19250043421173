import React from 'react';
import './App.css';
import './Responsive.css';

const Presentations = () => {
  const presentationList = [
    "University of Athens, School of Theology",
    "University for Peace, Peace & Conflict Studies",
    "Fordham University, Orthodox Christian Studies Center",
    "International Rescue Committee Welcome Week",
    "International Orthodox Theological Association",
    "American Academy of Religion Annual Meeting",
    "Trinity College Dublin",
    "St. Andrews University",
    "The Alexandrian Society",
  ];

  return (
    <div className="presentations-section">
      <h2>Presentations</h2>
      <ul>
        {presentationList.map((presentation, index) => (
          <li key={index}>{presentation}</li>
        ))}
      </ul>
    </div>
  );
};

export default Presentations;
