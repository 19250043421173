import React from 'react';
import './App.css';
import './Responsive.css';

const Gallery = () => {

return (
    <div className="gallery-section">
      <img id="presentations-1" src='presentations-1.jpg'/>
      <img id='gallery-1' src='gallery-1.png'/>
    </div>
  );
};

export default Gallery;