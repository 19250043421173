import React, {useState, useEffect, Component} from 'react';
import './App.css';
import './Responsive.css';
import Contact from './contact.js';
import Courses from './courses.js';
import Presentations from './presentations.js';
import Gallery from './gallery.js';

const ShakeLetter = ({ letter }) => {
  const isWhitespace = letter === ' ';
  const classNames = `shake-letter ${isWhitespace ? '' : 'shake'}`;

  return (
    <span className={classNames}>
      {isWhitespace ? '\u00A0' : letter}
    </span>
  );
};

function App() {
  const [backgroundColor, setBackgroundColor] = useState('rgb(23, 23, 23)');
  const [textColor, setTextColor] = useState('ivory');
  const [invertImage, setInvertImage] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollOpacity, setScrollOpacity] = useState(1);
  const [animateTable, setAnimateTable] = useState(false);
  const [contactOpacity, setContactOpactiy] = useState(1);
  const [animateBooks, setAnimateBooks] = useState(false);

  const [scrollPosition2, setScrollPosition2] = useState(0);
  const [scrollOpacity2, setScrollOpacity2] = useState(1);

  useEffect(() => {
    const handleScroll2 = () => {
      const currentScroll2 = window.scrollY;
      setScrollPosition2(currentScroll2);
    };

    window.addEventListener('scroll', handleScroll2);
    return () => {
      window.removeEventListener('scroll', handleScroll2);
    };
  }, []);

  const lineHeight2 = scrollPosition2 * 1.2;


  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      setScrollPosition(currentScroll);

      if (currentScroll > 1) {
        setScrollOpacity(0);
        setContactOpactiy(0)
      } else {
        setScrollOpacity(1);
        setContactOpactiy(1)
      }

      if (currentScroll > 700) {
        setAnimateTable(true);
      } else {
        setAnimateTable(false);
      }

      if ((currentScroll > 1800)) {
        setAnimateBooks(true);
      } else {
        setAnimateBooks(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const lineHeight = scrollPosition * 0.8;

  const toggleColors = () => {
    const newBackgroundColor = backgroundColor === 'rgb(23, 23, 23)' ? 'rgb(230, 227, 216)' : 'rgb(23, 23, 23)';
    const newTextColor = textColor === 'ivory' ? 'rgb(89, 73, 54)' : 'ivory';

    setInvertImage(!invertImage);
    
    setBackgroundColor(newBackgroundColor);
    setTextColor(newTextColor);
  };

  const text = "Andrew Sharp";
  const letters = text.split('');

  return (
    <div id='website'>
      <header className="header">

        <div className='about-container'>
          <h1 style={{ color: textColor }}>
            {letters.map((letter, index) => (
              <ShakeLetter key={index} letter={letter} />
            ))}
          </h1>
          <p>
             is a Lecturer and Senior Public & Community Engagement Manager at Virginia Tech, has two master’s degrees
             in theology and Religious Studies (emphasizing different faith traditions), and a PhD in comparative 
             religion and interfaith relations. He was Associate Editor of an international interfaith digest 
             (based in London) and is currently on the advisory board for the journal Salt: Crossroads of Religion 
             and Culture (based in Athens). He has held academic, research, and program administrative posts at 
             leading institutions in the United States, was a multifaith university chaplain, and served in 
             state and local government in programs aimed at protecting and serving vulnerable populations 
             (among refugees, elderly adults, and children). He has published his own independent research in 
             the fields of international relations, religious studies, and global ethics and has managed collaborative 
             research efforts in higher education and the public sphere. He has dedicated much of his life and career 
             to multicultural engagement, interreligious dialogue, and deep listening with compassion, which he 
             believes are key components of a thriving civil society. 
          </p>
          
          
          <div className="scroll-line-container">
            <div className="scroll-center">
              <img id="scroll" src="/scroll.gif" style={{ opacity: scrollOpacity }} />
              <div id="line" style={{ height: `${lineHeight}px` }}></div>
              {animateTable && (
                <h1 id="table-title">Institutions</h1>
              )}
            </div>
          </div>
        </div>

        <div className='image-container'>
          <div className='dotted-blob'></div>
          <div className='solid-blob'></div>
          <div className='outline-blob'></div>
          <div className='circle'>
            <img className='about-image' src={process.env.PUBLIC_URL + '/about-image.png'} alt="image of andrew..." />
          </div>
        </div>

        

      </header>

      <div className={`table ${animateTable ? 'animate' : ''}`}>
        <table>
          <tbody>

            <tr>
              <td></td>
              <td id='institutions'><h4>PRESENT:</h4></td>
            </tr>

            <tr>
              <td><img src='/vt-favicon.png' alt='VT Favicon' /></td>
              <td>Virginia Tech, School of Professional and International Affairs, Lecturer and Senior Public and Community engagement Manager</td>
            </tr>
            <tr>
              <td><img src='/vcu-favicon.jpg'></img></td>
              <td>Virginia Commonwealth University, School of World Studies, Affiliate Assistant Professor of Religious Studies</td>
            </tr>

            <tr>
              <td></td>
              <td><h4>PREVIOUS:</h4></td>
            </tr>

            <tr>
              <td><img src='/su-favicon.jpg'></img></td>
              <td>South University, Doctor of Ministry Program, Associate Professor and Program Director</td>
            </tr>
            <tr>
              <td><img src='/ur-favicon.jpg'></img></td>
              <td>University of Richmond, Office of the Chaplaincy, Program Specialist for Multifaith Initiatives and Adjunct Assistant Professor</td>
            </tr>
            <tr>
              <td><img src='/uv-favicon.webp'></img></td>
              <td>University of Virginia, Institute for Advanced Studies in Culture, Program Manager and Research Scholar</td>
            </tr>
            <tr>
              <td><img src='/wm-favicon.jpg'></img></td>
              <td>College of William and Mary, Department of Religious Studies, Visiting Instructor of Islam</td>
            </tr>

            <tr>
              <td></td>
              <td><h4>GRADUATE EDUCATION:</h4></td>
            </tr>

            <tr>
              <td><img src='/ub-favicon.jpg'></img></td>
              <td>University of Birmingham, United Kingdom, Centre for the Study of Islam and Christian-Muslim Relations, PhD, Theology and Religion</td>
            </tr>
            <tr>
              <td><img src='/uv2-favicon.jpg'></img></td>
              <td>University of Virginia, Department of Religious Studies, MA, Religious Studies</td>
            </tr>
            <tr>
              <td><img src='/sv-favicon.png'></img></td>
              <td>St. Vladimir's Orthodox Theological Seminary, MA, Theology (concentration in Byzantine History)</td>
            </tr>
          </tbody>
        </table>
      </div>


      <div id='publications' className={`publications ${animateBooks ? 'animate' : ''}`}>
        <h1>Publications</h1>
        <div id='publications-container'>

          <a href='https://brill.com/display/title/21600?rskey=btG8f2&result=1'>
            <div id="book-1" class="book-left">
            <img class="cover" src="/book-1.png"></img>
              <div class="description">
            <h2>Orthodox Christians and Islam in the Postmodern Age (Brill, 2012)</h2>
            <p>The patristic, ecclesiological, and liturgical revival in the Orthodox Church has had a profound impact
               on world Orthodoxy and the ecumenical movement. Orthodox leaders have also contributed to the movement’s 
               eforts in inter-religious dialogue, especially with Muslims. Yet this book is the rst comprehensive attempt 
               to assess an Orthodox ‘position’ on Islam. It explains why, despite being neighbors for centuries, relations 
               between Orthodox Christians and Muslims have become increasingly complex as internal and external forces 
               challenge their ability to understand each other and live in peace. It demonstrates how a growing number
               of Orthodox scholars and leaders have reframed the discussion on Islam, while endorsing and participating in
               dialogue with Muslims. It shows how a positive relationship with Muslims (and Islam in a general sense) 
               is an essential aspect of Orthodox Christians’ historical past, present identity, and future aspirations.</p>
              </div>
            </div>
          </a>

          <a href='https://www.tekinyayinevi.com.tr/postmodern-cagda-ortodoks-hristiyanlar-ve-islamiyet'>
            <div id="book-2" class="book-left">
              <img class="cover" src="/book-2.jpg"></img>
                <div class="description">
            <h2>Postmodern Çağda Ortodoks Hristiyanlar ve İslamiyet (Turkish; Tekin Publishing, 2016)</h2>
            <p>This book discusses the relations between Orthodox Christians and Muslims, the differences between the two
               religions, the similarities that allow us to commemorate them, on historical ground. It puts before us 
               the view of the members of both religions to a possible cooperation or calls for cooperation, and the possible
               reasons behind this call for cooperation. He insists that terrorism will not be a religion, especially against 
               the discriminatory approaches that accuse all Muslims after the September 11 attack. Are religions against
               globalization and what technology brings, or against the corruption that comes with these changes?
               Will the unity of religions provide a revolutionary upheaval in this postmodern age where nothing is tangible?
               Will religions be able to stand in the way of the world becoming a huge McDonalds?</p>
                </div>
            </div>
          </a>

          <a href='https://www.routledge.com/Routledge-Handbook-on-Christian-Muslim-Relations/Thomas/p/book/9780367870799'>
            <div id="book-3" class="book-left">
              <img class="cover" src="/book-3.png"></img>
                <div class="description">
            <h2>The Eastern Churches and Islam in Routledge Handbook on Christian-Muslim Relations (Routeledge, 2018)</h2>
            <p>‘The Eastern Churches and Islam’ in The Routledge Handbook of Christian-Muslim Relations, D. Thomas, ed. (Routledge, 2017) 
               An overview of modern encounters between Eastern Orthodox Christians and Muslims throughout the world.</p>
                </div>
            </div>
          </a>

          <a href='https://brill.com/display/book/9789004297210/B9789004297210_029.xml'>
            <div id="book-4" class="book-right">
              <img class="cover" src="/book-4.jpg"></img>
                <div class="description">
            <h2>Orthodox Christians, Muslims, and the Environment: The Case for a New Sacred Science" in The Character of Christian-Muslim Encounter (Brill, 2015)</h2>
            <p>An exploration of the perspectives Orthodox 
               Christians and Muslims on global warming and the global ecological crisis where key figures have articulated 
               an alternative worldview to the mainstream and called for a new ‘sacred science’ on the order of nature.  </p>
                </div>
            </div>
          </a>

          <a href='https://saltalas.com/salt-issues/1-22a07/'>
            <div id="book-5" class="book-right">
              <img class="cover" src="/book-5.jpg"></img>
                <div class="description">
            <h2>Toward a Global Ethic in Orthodoxy: Interreligious Engagement Beyond Dialogue" in Salt: Crossroads of Religion and Culture, n. 1 (2022)</h2>
            <p>‘Toward a Global Ethic in Orthodox: Interreligious Engagement Beyond Dialogue’ in Salt: Crossroads of Religion and 
               Culture, n. 1 (2022): 75-97. In an age when people across the globe question the effectiveness of governments and 
               the United Nations, this article considers the role that religion can play in helping to meet today’s most vexing 
               global challenges. Orthodox leaders at all levels of influence (be it international, regional, national, or local) 
               can most effectively respond to global issues by engaging in dialogue with those of other religious traditions and 
               working together with them toward practical solutions.</p>
                </div>
            </div>
          </a>

          <a href='https://pemptousia.com/2014/11/was-there-ever-a-truly-natural-city-the-byzantines-thought-so/'>
            <div id="book-6" class="book-right">
              <img class="cover" src="/book-6.png"></img>
                <div class="description">
            <h2>Was there ever a truly natural city? The Byzantines thought so" in Pemptousia, 22 Nov 2014</h2>
            <p>‘Was there ever a truly natural city? The Byzantines thought so’ in Pemptousia (22 November 2014).
               In an age that emphasizes sustainable urbanization and green growth in cities across the globe, 
               it makes sense to look to the past to see if human beings have ever really attempted, much less achieved, 
               a truly “natural” city—one in which the human and the natural were connected so that both would thrive. 
               As it turns out, new research suggests that Constantinople may have been such a city, at least in the 
               aspirations of its inhabitants.</p>
                </div>
            </div>
          </a>

        </div>
      </div>

      <div className='gallery-column'>
        <div className='text-gallery-column'>
          <div id='presentations'>
            <Presentations />
          </div>

          <div className="scroll-line-container-2">
            <div className="scroll-center">
              <div id="line2" style={{ height: `${lineHeight2}px` }}></div>
            </div>
          </div>

          <div id='courses'>
            <Courses />
          </div>
        </div>

        <div id='gallery' className='gallery'>
          <Gallery />
        </div>
      </div>

      <div id='contact'>
        <Contact />
      </div>

      <nav>
        <ul>
          <li><a href='#top'>about</a></li>
          <li><a href='#institutions'>institutions</a></li>
          <li><a href='#publications'>publications</a></li>
          <li><a href='#presentations'>presentations</a></li>
          <li><a href='#courses'>courses</a></li>
          <li><a href='#contact'>contact</a></li>
        </ul>
      </nav>

      <button id="color-mode" onClick={toggleColors} className={invertImage ? 'invert-image' : ''}>
        <img src={process.env.PUBLIC_URL + '/color-icon.png'} alt="icon" style={invertImage ? {filter: 'none'} : {}} ></img>
      </button>

      <a href='#contact' className='bottom-button' style={{ opacity: scrollOpacity }}>contact</a>

      <style>
        {`
          body {
            background-color: ${backgroundColor};
            color: ${textColor};
          } nav a {
            color: ${textColor};
          } #table-title {
            font-family: "Mosk", sans-serif;
          
            display: block;
            margin: 0 auto;
            padding: 0;
            font-size: 35px;
            
            animation: fade-in 1s ease-in;
            opacity: ${animateTable ? '1' : '0'};
            transform: ${animateTable ? 'translateY(0)' : 'translateY(40px)'};
          }
          
          @keyframes fade-in {
            from {
              opacity: 0;
              transform: translateY(40px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
}

export default App;
