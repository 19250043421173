import React from 'react';
import './App.css';
import './Responsive.css';

const Courses = () => {
  const courses = [
    {
      title: 'Christian Studies :',
      courses: [
        'Theology Survey',
        'Bible as Literature',
        'Eastern Christianity',
        'Old Testament Context and Theology',
        'New Testament Context and Theology',
      ],
    },
    {
      title: 'Islamic Studies :',
      courses: [
        'Intro to Islam',
        'Freshman Seminar on Islam',
        'Islam in the Modern World',
        'Islam, Nationalism, & the West',
        'Islamic Mysticism: The Sufis',
      ],
    },
    {
      title: 'Religious Studies :',
      courses: [
        'Intro to Religious Studies',
        'Western Religions',
        'World Religions',
        'Religions in America',
      ],
    },
    {
      title: 'Interreligious Engagement :',
      courses: [
        'Muslim-Christian Relations Past and Present',
        'Bridging Religious Difference',
        'Pilgrimage Spain: Judaism, Christianity, and Islam',
        'Muslim-Christian Relations: History and Dialogue',
      ],
    },
    {
      title: 'Global Ethics :',
      courses: [
        'Global Ethics and World Religions',
        'Global Ethics and World Religions: Service Learning',
      ],
    },
    {
      title: 'Ministry Studies :',
      courses: [
        'Diversity in Ministry',
        'Ministry Practicum I',
        'Ministry Practicum II',
        'Community & Discipleship',
        'Learning Skills for Ministry',
        'Theology and Research in Ministry',
      ],
    },
    {
      title: 'Public Administration :',
      courses: [
        'Concepts and Approaches to Public Administration',
        'Government Administration II: System Skills for Managers',
      ],
    },
  ];

  return (
    <div className="courses-section">
      <h2>Courses Taught</h2>
      {courses.map((category, index) => (
        <div key={index}>
            <br></br>
          <h4>{category.title}</h4>
          <ul>
            {category.courses.map((course, courseIndex) => (
              <li key={courseIndex}>{course}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Courses;
