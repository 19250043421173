import React, { useState, useEffect } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFocus = (event) => {
    const label = event.target.previousSibling;
    label.style.transform = 'translateX(-110%)';
    label.style.fontSize = '12px';
  };

  const handleBlur = (event) => {
    const label = event.target.previousSibling;
    if (event.target.value === '') {
      label.style.transform = 'none';
      label.style.fontSize = 'inherit';
    }
  };

    return (
        <div id="contact">
            <form 
              action="https://formkeep.com/f/5fcc81b853e5"
              accept-charset="UTF-8"
              enctype="multipart/form-data"
              method="POST">
                <div className="input-container">
                    <label htmlFor="name">name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                    />
                </div>

                <div className="input-container">
                    <label htmlFor="email">email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                    />
                </div>

                <div className="input-container">
                    <label htmlFor="message">message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        rows="4"
                        cols="50"
                        required
                    ></textarea>
                </div>

                <input id='submit' type="submit" value="Submit" />
            </form>

            <div className='contact-me'>
            <h1>Contact<br /> Me...</h1>
            <p>by email: andrewsharp@vt.edu</p>
            
            </div>

        </div>
    );
};

export default ContactForm;